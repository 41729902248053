import styled from 'styled-components';

const HomePage = () => {
  return (
    <Body>
      <h1>Senat-studio</h1>
      {/* <p>По вопросам обращаться +7 (989) 707-22-00</p> */}
    </Body>
  );
}

export default HomePage;

const Body = styled.main`
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: var(--light);

  h1 {
    font-family: 'Solitreo', cursive;
    font-size: 40px;
  }

  p {

  }
`;
